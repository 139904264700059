<ion-menu contentId="main-content" swipeGesture="false">
  <div class="menu-header viva-page">
    <h1 *ngIf="!profile">Your <span class="secondary">Profile</span></h1>
    <h3 *ngIf="profile" class="primary">{{profile.firstName}} <span class="secondary">{{profile.lastName}}</span></h3>
    <h4 *ngIf="emailAddress && emailVerified">{{emailAddress}} </h4>
    <h4 *ngIf="emailAddress && !emailVerified" class="email-not-verified">{{emailAddress}} </h4>
    
    <h4 *ngIf="profile">{{profile.dateOfBirth | date:'EEEE dd MMM yyyy' }} </h4>

    <h4 class="uat-environment" *ngIf="!isProduction">UAT</h4>
    <small class="uat-environment" *ngIf="!isProduction && installSource">{{installSource}}</small>
  </div>
  <ion-content class="ion-padding menu-border">
    <ion-list>

      <ion-item class="menu-item" (click)="myProfile()">
        <ion-img src="assets/v2/menu/menu_profile.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.profile' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="changePassword()">
        <ion-img src="assets/v2/menu/menu_password.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.password' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="changeEmail()">
        <ion-img src="assets/v2/menu/menu_change_email.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.emailchange' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <div class="menu-spacer"></div>

      <ion-item class="menu-item" *ngIf="isDashboardAllowed" (click)="dashboard()">
        <ion-img src="assets/v2/menu/menu_organisation.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{'main-menu-component.items.organisation-dashboard' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" *ngIf="isDashboardAllowed" (click)="powerbi()">
        <ion-img src="assets/v2/menu/menu_organisation.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{'main-menu-component.items.powerbi-dashboard' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>




      <ion-item class="menu-item" *ngIf="chatEnabled" (click)="mediaChatbot()">
        <ion-img src="assets/v2/menu/menu_chat.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{'main-menu-component.items.chat-with-us' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="vivaScoreLite()" *ngIf="scanFriendAllowed">
        <ion-img src="assets/v2/menu/menu_scan_friend.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.viva-lite' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="gotoMedia()">
        <ion-img src="assets/v2/menu/menu_knowledge.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.knowledge-centre' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <!--
      <ion-item class="menu-item">
        <ion-img src="assets/v2/menu/menu_invite_friend.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.invite-a-friend' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
-->

      <!--
      <ion-item class="menu-item">
        <ion-img src="assets/v2/menu/menu_points.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.points' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
-->

      <div class="menu-spacer"></div>

      <ion-item class="menu-item" (click)="faq()">
        <ion-img src="assets/v2/menu/menu_about.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.faq' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <!--
      <ion-item class="menu-item">
        <ion-img src="assets/v2/menu/menu_rate.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.rate-us' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
-->

      <ion-item class="menu-item" (click)="contactUs()">
        <ion-img src="assets/v2/menu/menu_contact.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.contact-us' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
      <!--
      <ion-item class="menu-item" >
        <ion-img src="assets/v2/menu/menu_consent.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.consent' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
-->
      <ion-item class="menu-item" (click)="openUrl('https://www.alulatechnologies.com/privacy-policy')">
        <ion-img src="assets/v2/menu/menu_privacy_policy.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.privacy-policy' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="openUrl('https://www.alulatechnologies.com/terms-and-conditions')">
        <ion-img src="assets/v2/menu/menu_terms_of_use.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.terms-conditions' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>
      <div class="menu-spacer"></div>

      <ion-item class="menu-item" (click)="deleteAccount()">
        <ion-img src="assets/v2/menu/menu_delete_account.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.delete-account' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>

      <ion-item class="menu-item" (click)="logout()">
        <ion-img src="assets/v2/menu/menu_logout.png" slot="start" class="menu-icon"></ion-img>
        <ion-label>
          <h3>{{ 'main-menu-component.items.logout' | translate }}</h3>
        </ion-label>
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-item>


    </ion-list>
  </ion-content>
</ion-menu>